/* You can add global styles to this file, and also import other style files */
@import './styles/main';
* {
  box-sizing: border-box;
}

.page--title {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

// TODO: Mover esto al cds pero con un mejor control de z-index
#_CDS_LOADING_PORTAL_ID,
#_CDS_ALERT_PORTAL_ID {
  position: sticky;
  z-index: 99999;
}

.toast__description {
  color: black;
}

.text--wrap {
  word-break: break-all;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

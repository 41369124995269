@import '@cds.js/core/dist/cargamos/cargamos.css';
@import './base/breakpoints';

@import 'plugins/plugins';

:root {
  --hepha-primary-color: rgb(18, 18, 18);
  --hepha-white-color: #fff;
  --hepha-font-color: #c8c5c5;
  --hepha-background: #121212; //rgb(18, 18, 18);
  --hepha-sidebar-background: rgb(63, 63, 63);
  --cds-tooltip-font-color: #fff;

  // cds styles
  --cds-button-only-icon-color: #fff;
  --cds-tooltip-background: #000;
  --cds-button-secondary-background: #000;

  --cds-card-background: #343333;
  --cds-card-header-color: #fff;

  --cds-primary-color: #ffffff;
  --cds-secondary-color: #f97901;

  --cds-button-color: #000;

  --cds-alert-background: #343333;
  --cds-alert-font-color: #fff;

  --cds-tab-selected-color: var(--cds-secondary-color);
  --cds-tab-selected-border-bottom: var(--cds-secondary-color);
  --cds-tab-unselected-border-bottom: var(--cds-secondary-color);
  --cds-tab-unselected-color: #fff;

  --cds-empty-state-text-color: #fff;

  --cds-modal-background: #343333;

  --cds-breadcrumb-color: var(--cds-secondary-color);
  --cds-breadcrumb-hover-color: #fff;

  --cds-accordion-open-background: #121212;
  --cds-accordion-close-background: #121212;

  --cds-form-label-color: var(--cds-secondary-color);
  --cds-form-input-background: #4b4848;
  --cds-form-input-hover: var(--cds-secondary-color);
  --cds-placeholder-color: #fff;
  --cds-general-box-shadow-color: #f09138;
  --cds-form-input-error-background: var(--cds-form-input-background);
  --cds-dropdown-list-background: var(--cds-form-input-background);
  --cds-dropdown-item-hover-background: #e29042;
  --cds-dropdown-item-selected-background: var(--cds-secondary-color);

  --cds-loading-background-color: #343333;

  --cds-table-th-background: #000;

  --cds-menu-list-background: #343333;
  --cds-menu-border-color: #fff;
  --cds-menu-item-hover-background: #e29042;
  --cds-menu-item-font-color: #fff;

  --cds-form-input-color: #fff;
}

html {
  background: var(--hepha-background);
}

body {
  color: var(--hepha-font-color);
}
